import React from "react"
import { Carousel } from "react-bootstrap"

export const Testimonials = () => {
  return (
    <section className="bg-light text-center">
      <div className="container pb-4">
        <h2 className="section-heading">Testimonials</h2>

        <div className="row mt-n4">
          <div className="col-lg-9 mx-auto">
            <Carousel
              slide={false}
              className="testimonial-carousel"
              controls={false}
              interval={null}
            >
              <Carousel.Item>
                <blockquote>
                  <p className="lead">
                    James Devenney built our architecturally designed family
                    holiday/retirement home in Martinborough in 2019. I was
                    retiring from a lifetime in the construction industry in
                    Auckland and I worked closely with James throughout the
                    build. James is a craftsman builder, well organised,
                    accurate with a very high level of competency and integrity
                    and I would not hesitate to recommend him to any potential
                    client.
                  </p>
                  <footer>
                    <cite>- Kirk Gunman</cite>
                  </footer>
                </blockquote>
              </Carousel.Item>
              <Carousel.Item>
                <blockquote>
                  <p className="lead">
                    James and his team built our dwelling with a high standard
                    of professionalism and positive attitude throughout the
                    project. The team had an eye for detail which was enormously
                    helpful to this particular project. They remained determined
                    to get the job done when complexities or challenges arose.
                  </p>
                  <p className="lead">
                    James' team alongside his subcontractors, promptly responded
                    to variations made on site with a can-do attitude. Their
                    performance as a team was exceptional.
                  </p>
                  <footer>
                    <cite>- Philippe Campays</cite>
                  </footer>
                </blockquote>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
