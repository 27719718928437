import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Testimonials from "../components/testimonials"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <section className="banner">
      <StaticImage
        className="banner__image"
        src="../images/new-builds/IMG-20210108-WA0071.jpg"
        alt="Lounge and dining room"
        loading="eager"
      />
      <div className="banner__content">
        <h1 className="mb-4">
          High quality builds and renovations that our clients love to call home
        </h1>
        <p className="mb-5 lead">
          Building architecturally designed houses and completing stunning home
          transformations in Wellington and the Wairarapa Region. We build
          beautiful homes that we are proud to put our name to, and that our
          clients love living in.
        </p>
        <Link to="/contact/" className="btn btn-primary">
          Get in touch
        </Link>
      </div>
    </section>

    <section className="bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <h2 className="section-heading section-heading--left">
                About us
              </h2>
              <p>
                Devenny Construction builds high quality homes that our clients
                love to live in. Delivering a superior service that ensures
                peace of mind, James Devenny, owner/ builder, prides himself in
                going the extra mile to ensure a beautiful finish on every job.
                A detailed knowledge of new building materials and processes
                makes Devenny Construction a sound choice for all your building
                needs.
              </p>
              <p>
                Whether it’s your dream new build or a refreshing renovation for
                your much-loved home, James will be on site to monitor progress
                and performance every step of the way. When building with
                Devenny Construction you can be sure no short cuts are taken and
                that the team always has an eye on perfection. Proven
                reliability and outstanding professional standards ensures
                client satisfaction every time.
              </p>
            </div>
          </div>
          <div className="col-lg-5 mt-4 mt-lg-0 ml-auto d-flex align-items-center">
            <StaticImage
              src="../images/IMG_1802.jpg"
              alt="James at Maximus standing in front of Devenny Construction vehicle"
            />
          </div>
        </div>
      </div>
    </section>

    <section className="">
      <div className="container">
        <h2 className="section-heading">Services</h2>
        <div className="row">
          <div className="col-lg-4 mb-5">
            <Link to="/new-builds/" className="card-link">
              <div className="card shadow">
                <StaticImage
                  className="card-img-top"
                  aspectRatio={16 / 11}
                  src="../images/new-builds/IMG-20210108-WA0071.jpg"
                  alt="Lounge and dining room"
                />
                <div className="card-body">
                  <div>
                    <h5 className="card-title">New Builds</h5>
                    <p className="card-text mb-3">
                      Working with your plans, our team can make your dream home
                      a reality.
                    </p>
                  </div>
                  <span className="btn btn-sm btn-primary">Learn more</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 mb-5">
            <Link to="/renovations/" className="card-link">
              <div className="card shadow">
                <StaticImage
                  className="card-img-top"
                  aspectRatio={16 / 11}
                  src="../images/renovations/007.jpeg"
                  alt="Path to newly renovated house"
                />
                <div className="card-body">
                  <div>
                    <h5 className="card-title">Renovations</h5>
                    <p className="card-text mb-3">
                      Dreaming of a renovation to enhance your home’s beauty? We
                      can help with that.
                    </p>
                  </div>
                  <span className="btn btn-sm btn-primary">Learn more</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 mb-4">
            <Link to="/home-improvements/" className="card-link">
              <div className="card shadow">
                <StaticImage
                  className="card-img-top"
                  aspectRatio={16 / 11}
                  transformOptions={{ cropFocus: "bottom" }}
                  src="../images/home-improvements/marty-reclad/1.webp"
                  alt="New fence"
                />
                <div className="card-body">
                  <div>
                    <h5 className="card-title">Home Improvements</h5>
                    <p className="card-text mb-3">
                      Need a small job done to make improvements to your home?
                      We can fit you in.
                    </p>
                  </div>
                  <span className="btn btn-sm btn-primary">Learn more</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>

    <Testimonials />
  </Layout>
)

export default IndexPage
